@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
/* Header.css */

* {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  background-color: #2b2b2b;
  overflow-x: hidden;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode:forwards;
}


@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

header {
  background:#2b2b2b;
  height: 5rem;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 5rem 1rem 5rem;
}

.ham_menu {
  background: transparent;
  border-color: transparent;
  font-size: 1.75rem;
  font-weight: 700;
  color: #C6A47E;
  cursor: pointer;
  padding-top: 1rem;
}

.close {
  padding-top: 4rem;
  padding-right: 2rem;
  background: transparent;
  border-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  color: #d5d5d5;
  cursor: pointer;
  align-self: flex-end;
}


.nav {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 2rem;
  gap: 2rem;
  transition: height 0.3s ease;
  padding-right: 1rem;
}


.nav ul {
  border-radius: 2rem;
  list-style: none;
  display: flex;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  font-size: 1rem;
  margin-right: 1rem;
  padding-left: 0.75rem;
  color: #ffffff;
  font-weight: 600;

}

.nav ul li a.active {
  color: #c6a47e; /* Color for active item */
}

.nav a {
  position: relative;  /* Add position relative to the anchor tag */
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav li:not(.logo_text):hover {
  width: 100%;
  color: #666666;
}


.logo_text {
  font-size: 1.5rem;
  padding-top: 0.75rem;
  font-weight: 700;
  color: #C6A47E;
  padding-left: 6rem;
  
}


.nav_side ul {
  position: fixed;
  margin-top: 0;
  padding-left: 0;
  top: 0;
  right: 0;
  height: 110dvh;
  width: 100%;
  overflow-y: 0;
  z-index: 5;
  background-color: #2b2b2b;
  display: none;
  flex-direction: column;
  align-items: center;
  list-style: none;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  font-size: 1.25rem;

}

.nav_side ul li {
  border-bottom: solid 1px #d5d5d5;
  width: 60%;
  padding-left: 20%;
  padding-bottom: 0.5rem;
  align-items: center;

}

.nav_side a {
  color: #C6A47E;
  text-decoration: none;
  position: relative;

}

.nav_side a::before  {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #C6A47E;
  transition: width 0.2s ease-in-out;
}

.nav_side li:hover a::before {
  width: 100%;
}

@media all and (max-width: 1024px) {
  .nav ul li, .login_btn {
    display: none;
  }
  .nav {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-right: 0rem;
  }
 
  .logo_text {
    font-size: 1.25rem;
    padding-top: 0rem;
    padding-left: 0;

  }

  header {
    background:#2b2b2b;
    height: 5rem;
    top: 0;
    left: 0;
    width: 100vw;
    align-items: center;
    padding: 2rem 0rem 0rem 0.5rem;
    border-bottom: solid 1.5px #C6A47E;
  }

  .ham_menu {
    padding-top: 0;
  }


}

@media all and (min-width: 1024px) {
  .ham_menu {
    display: none;
  }

  .nav_side {
    display: none;
  }
}

/* Footer.css */
footer {
    background-color: #1C1C1C;   
    color: #d5d5d5;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    padding-left: 2rem;
    padding-bottom: 0.5rem;

  }

.footer-contact-info ,.footer-location-info {
  margin-left: 0.5rem;
}

.footer_contact_li {
  display: flex;
  font-size: 1.1rem;
}
  
.footer_txt::before {
  content: "";
  display: block; 
  border-top: 1px solid #d5d5d5;
  margin-bottom: 10px;
  margin-right: 2rem;
 } 

.footer_info_container {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  font-size: 1.25rem;
  padding-top: 1rem;
 }

 .footer_info{
  list-style: none;
  padding: 0;
  margin: 0;
  width: 18rem;

 }

 .footer_link_projects {
   text-decoration:none ;
   color: #ffffff;
   border-bottom: solid 2px #C6A47E;
 }

 .footer_icon {
  margin-right: 1rem;
  color: #C6A47E;
 }


.footer_info li h2 {
  font-size: 1.25rem;
  color: #C6A47E;
}

.footer_info li p {
  font-size: 1.1rem;
}

.social_apps {
  padding-top: 1rem;
  color: #C6A47E;
  
}

.social_icon {
  cursor: pointer;
  margin-right: 1rem;
}

 @media all and (max-width:600px) {
  footer {
    padding-left: 1rem;
    padding-right: 0rem;
  
  }

  .footer_info_container {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 3rem;
  }


   .footer-contact-info ,.footer-location-info {
    margin-left: 0rem;
  }

  .footer-contact-info span{
    font-size: 1rem;
  }

  .footer_info li h2 {
    font-size: 1.3rem;
  }
.footer_info {
  width: 95%;

}
 
 }
/* Projects.css */
.projects_sec {
    width: 100vw;
    overflow: hidden;
    background-color: #2B2B2B;
    display: grid;
    place-items: center;
  }


  
.h_projects {
    font-size: 1.75rem;
    padding-left: 4rem;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    color: #D5D5D5;
    font-weight: 400;
  }

.projects_container {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  width: 100vw;

}

.projects_sample {
  text-decoration: none;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 20rem;
  grid-gap: 3rem;
  gap: 3rem;
}


.project_image {
  width: 30rem;
  height: 22.5rem;
}

.project_sample_block {
  width: 35vw;

}

.project_sample_block h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #D5D5D5;
}

.project_sample_block h3 {
  cursor: pointer;
  font-size: 1.5rem;
  color: #957B5F;
  padding-bottom: 0.5rem;
}

.project_sample_block p {
  color: #D5D5D5;
  font-weight: 600;
  line-height: 1.75;
}

.view_project_text {
  font-size: 1.1rem;
  text-decoration: none;
  color: #957B5F;
  cursor: pointer;
  font-weight: 700;
}

.explore_text_container {
  display: grid;
  place-items: center;
  margin: 0 auto;
  background-color: #C6A47E;
  cursor: pointer;
}

.explore_text{
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  
}

.explore_text:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}


@media all and (max-width: 1024px) {
  .projects_container {
    flex-direction: column;
    margin-left: 0;
    margin-top: 3rem;
  }

    .projects_sample {
    display: flex;
    flex-direction: column;
    width: 100vw;
    border-left: 0;
    padding-bottom: 2rem;
    padding: 0;
    
  }
  .projects_sample img {
    width: 100vw;
    height: 20rem;

  }

  .project_sample_block {
    width: 100%;
    padding: 0 2.5rem 0 2.5rem;
    letter-spacing: 1px;
  }

  .h_projects {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 2rem;
  }


  .projects_p {
    width: 100%;
  }

  .explore_text_container {
    text-align: center;

  }

  .projects_sec {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
}

.contact_sec {
    background-color: #2b2b2b;
  }

.contact_container {
  display: flex;
  justify-content: space-between;
}

.left_contact_part {
  width: 50vw;
  background-color: #2b2b2b;
  padding-left: 10rem;

}

.contact_details {
  width: 50vw;
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #444444;
  padding-right: 6rem;
  padding-top: 2rem;
  margin-bottom: 0;
  margin-top: 0;
}

.contact_details iframe{
   width: 90%;
   height: 18rem;
   margin-bottom: 1rem;
}


.contact_icon {
  color:#C6A47E ;  
  font-size: 1.5rem;
  font-weight: 600;

}

.contact_li {
  display: flex;
}

.contact_info {
  margin-left: 1rem;
  color:#C6A47E ;  
  font-size: 1.25rem;

}

.h_contact {
  color: #C6A47E;
  font-size: 3.5rem;

}

.p_contact {
  color: #e3cfb9;
  font-size: 1.25rem;
}

.contact_form form {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  color: #C6A47E;
  line-height: 2;
  width: 28vw;
  
}

.send_button {
  font-size: 1rem;
  cursor: pointer;
  background-color: #C6A47E;
  margin-top: 1rem;
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  width: 5.5rem;
}

.send_button:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}

.contact_input {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.contact_form form textarea {
  height: 12vh;

}

@media all and (max-width:1024px) {
  .contact_details {
    margin-left: 0;

  }
  
  .contact_sec{
    display: flex;
    flex-direction: column;  
  }

  .contact_container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .left_contact_part {
    padding-left: 1rem;
    width: 100%;
  }

  .contact_form form {
    width: 90vw;
    margin-bottom: 2.5rem;
  }

  .contact_details {
    width: 100vw;
    padding-right: 0;

  }

  .contact_icon {
    font-size: 1.5rem;
  }

  .contact_info{
    font-size: 1.25rem;
  }

  .send_button {
    padding: 0.75rem 5rem 0.75rem 3rem;
    
}

}
/* Gallery.css */

.gallery_sec {
  background-color: #2b2b2b;
  width: 100vw;
  padding-bottom: 2rem;
  border-bottom: solid 2px #957b5fc7;

}

.gallery_intro {
    display: grid;
    place-items: center;
    padding-bottom: 3rem;
}

.gallery_intro h1{
    color: #C6A47E;
    font-size: 3.5rem;
    font-weight: 500;
}

.gallery_intro p {
    width: 50vw;
    text-align: center;
    color: #d5d5d5;
    line-height: 1.6;

}
  @media all and (max-width: 1024px) {

    .gallery_intro p {
      width: 90%;
      font-size: 1.1rem;
    }

    .gallery_intro h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    


  }
.image-slider {
  position: relative;
  left: 25%;
  width: 650px;
  height: 420px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.4);
}

.image-slider .images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-slider .images img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: scale(1.1);
  transition: all 500ms ease-in-out;
}

.image-slider .images img.active {
  opacity: 1;
  transform: scale(1);
}

.image-slider .back-btn,
.image-slider .next-btn {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 25px;
  background: rgba(0, 0, 0, 0.2);
  transition: all 300ms ease-in-out;
}

.image-slider .next-btn {
  right: 0;
}

.image-slider .back-btn:hover,
.image-slider .next-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}

.image-slider .thumbnails {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.image-slider .thumbnails img {
  width: 60px;
  cursor: pointer;
  border: 2px solid transparent;
}

.image-slider .thumbnails img.active {
  border: 2px solid #fff;
}

.image-slider .back-btn:before,
.image-slider .next-btn:before {
  content: '\276E';
  display: block;
}

.image-slider .next-btn:before {
  content: '\276F';
}
/* Home.css */


.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  
}

.back-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.625rem 1.25rem;
  background-color: #C6A47E;
  color: #2b2b2b;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 999;
  border: solid 2px #C6A47E;
}

.arrow_top {
  animation: bounce 2s infinite;

}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.back-to-top:hover {
  background-color: #2b2b2b;
  color: #C6A47E;
  border: solid 2px #C6A47E;

}


.background-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit:cover;

}

.text-block {
  position: absolute;
  display: grid;
  place-items: center;
  top: 30vh;
  padding-left: 2rem;
  width: 50%;
  background-color: #2B2B2B66;

}

.text-block h1 {
  font-size: 4rem;
  font-weight: 600;
  color: #C6A47E;
  text-shadow: 2px 2px 2px #1c1c1c;
  letter-spacing: 2px;

}
.text-block p{
  font-size: 1.5rem;
  padding-left: 0.5rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 2px 2px #1c1c1c;

}

.header-container {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 100;
}

.personal_statement_container {
  display: flex;
  justify-content: space-between;
  background-color: #555555;
  padding-bottom: 2.5rem;
  width: 100vw;
  margin-top: 85vh;
  padding: 4rem;

}

.personal_statement {
  background-color: #2B2B2B;
  padding: 4rem;
}

.personal_statement h2{
  font-size: 3.5rem;
  font-weight: 700;
  color: #C6A47E;
  margin-bottom: 0;
  letter-spacing: 2px;

}

.personal_statement h3 {
  margin: 0.75rem 0;
  font-size: 1.5rem;
  color: #e3cfb9;
  letter-spacing: 2px;

}

.personal_statement p{
  font-size: 1.2rem;
  color: #d5d5d5;
  line-height: 1.7;
}

.personal_pic_container img {
  height: 100%;
  width: 40vw;
}

.about_me_link {
  display: flex;
  justify-content: center;
  background-color: #C6A47E;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.about_me_text {
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border: solid #C6A47E 0.15rem;

}

.about_me_text:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}



@media all and (max-width: 600px) {
  
  .text-block h1 {
    font-size: 2rem;
  }
  .text-block p{
    font-size: 1.25rem;
  }

  .text-block {
    width: 80%;
    text-align: center;
    padding-left: 0;
    top: 25vh;


  }

  .background-image-container{
    padding-top: 0;
    width: 100vw;
    height: 70vh;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  .personal_statement_container {
    flex-direction: column;
    padding: 0;
    margin-top: 50vh;
  }

  .personal_statement h3 {
    font-size: 1.25rem;
    letter-spacing: 3px;
  }

  .about_me_link{
    margin: 0 auto;
    margin-top: 3rem;

  }

  .about_me_text {
    padding: 1rem 3.5rem 1rem 3.5rem;
    font-size: 1.25rem;
  }

  .personal_pic_container {
    width: 100vw;
    height: 50vh;
  }
  .personal_pic_container img {
    width: 100vw;
  }
  
}

/* About.css */
.about_sec {
    background-color: #2b2b2b;
    scroll-behavior: smooth;
  }

 
.about_text_container h1 {
    font-size: 3.5rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: left;
    color: #c6a47e;
    font-weight: 500;

  }


.p_about {
    width: 70%;
    font-size: 1.25rem;
    color: #d5d5d5;
    line-height: 1.3;
  }


.about_text_container {
  display: grid;
  place-items: center;
  padding-left: 4rem;
}

.cv_download_button {
  font-size: 1rem;
  cursor: pointer;
  background-color: #C6A47E;
  margin-top: 1rem;
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  width: -webkit-max-content;
  width: max-content;
}

.cv_download_button:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}

.education_container {
  margin: 2rem 4rem 3rem 4rem ;
  padding: 3rem 4rem 0rem 11.5rem;
  background-color: #555555;
}

.education_h {
  color: #d5d5d5;
  font-size: 2.5rem;
  font-weight: 400;
}

.education_info {
  display: flex;
  justify-content: space-between;
}

.education_year {
  color: #C6A47E;
  font-size: 1rem;

}

.education_year h3 {

  font-size: 1.2rem;
  margin-top: 0;
  color: #957b5f;
}

.education_year h2 {
  font-size: 1.11rem;
  color: #d5d5d5;
  margin-top: 0;

}

.education_study {
  width: 50vw;
  margin-bottom: 3rem;
}

.education_study h2 {
  font-size: 1.1rem;
  color: #d5d5d5;
  font-weight: 700;
  margin-top: 0;

}

.education_study ul li {
  color: #d5d5d5;
}

@media all and (max-width:1024px) {
  .about_sec {
    padding-left: 0rem;
    text-align: center;

  }

  .about_text_container h1  {
    margin-top: 1rem;
    font-size: 2.5rem;
  }

  .about_text_container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .p_about {
    width: 89%;
    font-size: 1.1rem;
  }

  .education_container {
    width: 100%;
    margin: 2rem 0rem 0rem 0rem ;
    padding: 3rem 2rem 0rem 2rem;
    
  }

  .education_info {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .education_h {
    text-align: left;
  }

  .education_year h2 {
    font-size: 1.25rem;
    
   }
   
   .education_year h3 {
    font-size: 1.25rem;
    font-weight: 700;
    
   }

  .education_study {
    width: 90%;
    margin-bottom: 3rem;
    padding-right: 0;
    line-height: 2;

    }

  

  .education_study h2 {
    font-size: 1.25rem;
    font-weight: 700;

  }


}
/* Projects.css */
.allprojects_sec {
    height: 100%;
    background-color: #2b2b2b;
    scroll-behavior: smooth;

  }


.all_projects_h {
  font-size: 1.5rem;
  color: #d5d5d5;
  font-weight: 400;
  padding-left: 16rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 2px;

}

.all_projects_images {
  display: grid;
  place-items: center;
}

.project-container {
  position: relative;
  display: inline-block;
}

.project-container:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity (fourth parameter) as needed */
}

.project-container img {
  display: block;
  width: 60vw;
  height: 75vh;
  margin-bottom: 2.5rem;
  cursor: pointer;
  transition: filter 0.3s ease; /* Apply transition for smoother effect */
  object-fit:cover;

}

.project-container:hover img {
  filter: brightness(60%); /* Apply brightness filter on hover */
}

.image-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff; /* Ensure text is visible against the overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 3rem;
}

.project-container:hover .image-text {
  opacity: 1; /* Show the text on hover */
}


  

@media all and (max-width: 1024px) {
  .all_projects_images {
    display: block;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .all_projects_h {
    padding-left: 1.5rem;
  }
  
  .all_projects_images img {
    width: 100vw;
    height: 35vh;

  }

  
}
/* Projects.css */
.projectone_sec {
    height: 100%;
    width: 100vw;
    background-color: #2b2b2b;

  }


.projectone_h {
  font-size: 1.5rem;
  color: #d5d5d5;
  font-weight: 400;
  padding-left: 16rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 2px;

}

.project_description_container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2rem;
    grid-gap: 3rem;
    gap: 3rem;
    width: 65vw;
    padding-bottom: 2rem;
}

.project_description_title {
    width: 50%;
}

.project_description_title h2 {
    color: #C6A47E;
    letter-spacing: 2px;
}

.project_description_title h3 {
    color: #d5d5d5;
    letter-spacing: 2px;
}

.project_description_title p {
    color: #d5d5d5;
    letter-spacing: 2px;
}

.project_description_p {
    width: 50%;
    color: #d5d5d5;
    word-spacing: 4px;

}



  
  

@media all and (max-width: 1024px) {
  

  .projectone_h {
    padding-left: 1.5rem;
  }

  .project_description_container {
    display: block;
     width: 90vw;
  
  }

  .project_description_title {

     width: 100%;
     padding-bottom: 2rem;
  }
  
  .project_description_p {

     width: 100%;
  }

  
}
