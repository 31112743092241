/* Projects.css */
.allprojects_sec {
    height: 100%;
    background-color: #2b2b2b;
    scroll-behavior: smooth;

  }


.all_projects_h {
  font-size: 1.5rem;
  color: #d5d5d5;
  font-weight: 400;
  padding-left: 16rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 2px;

}

.all_projects_images {
  display: grid;
  place-items: center;
}

.project-container {
  position: relative;
  display: inline-block;
}

.project-container:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity (fourth parameter) as needed */
}

.project-container img {
  display: block;
  width: 60vw;
  height: 75vh;
  margin-bottom: 2.5rem;
  cursor: pointer;
  transition: filter 0.3s ease; /* Apply transition for smoother effect */
  object-fit:cover;

}

.project-container:hover img {
  filter: brightness(60%); /* Apply brightness filter on hover */
}

.image-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff; /* Ensure text is visible against the overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 3rem;
}

.project-container:hover .image-text {
  opacity: 1; /* Show the text on hover */
}


  

@media all and (max-width: 1024px) {
  .all_projects_images {
    display: block;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .all_projects_h {
    padding-left: 1.5rem;
  }
  
  .all_projects_images img {
    width: 100vw;
    height: 35vh;

  }

  
}