/* Home.css */


.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  
}

.back-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.625rem 1.25rem;
  background-color: #C6A47E;
  color: #2b2b2b;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 999;
  border: solid 2px #C6A47E;
}

.arrow_top {
  animation: bounce 2s infinite;

}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.back-to-top:hover {
  background-color: #2b2b2b;
  color: #C6A47E;
  border: solid 2px #C6A47E;

}


.background-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit:cover;

}

.text-block {
  position: absolute;
  display: grid;
  place-items: center;
  top: 30vh;
  padding-left: 2rem;
  width: 50%;
  background-color: #2B2B2B66;

}

.text-block h1 {
  font-size: 4rem;
  font-weight: 600;
  color: #C6A47E;
  text-shadow: 2px 2px 2px #1c1c1c;
  letter-spacing: 2px;

}
.text-block p{
  font-size: 1.5rem;
  padding-left: 0.5rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 2px 2px #1c1c1c;

}

.header-container {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 100;
}

.personal_statement_container {
  display: flex;
  justify-content: space-between;
  background-color: #555555;
  padding-bottom: 2.5rem;
  width: 100vw;
  margin-top: 85vh;
  padding: 4rem;

}

.personal_statement {
  background-color: #2B2B2B;
  padding: 4rem;
}

.personal_statement h2{
  font-size: 3.5rem;
  font-weight: 700;
  color: #C6A47E;
  margin-bottom: 0;
  letter-spacing: 2px;

}

.personal_statement h3 {
  margin: 0.75rem 0;
  font-size: 1.5rem;
  color: #e3cfb9;
  letter-spacing: 2px;

}

.personal_statement p{
  font-size: 1.2rem;
  color: #d5d5d5;
  line-height: 1.7;
}

.personal_pic_container img {
  height: 100%;
  width: 40vw;
}

.about_me_link {
  display: flex;
  justify-content: center;
  background-color: #C6A47E;
  cursor: pointer;
  width: fit-content;
}

.about_me_text {
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border: solid #C6A47E 0.15rem;

}

.about_me_text:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}



@media all and (max-width: 600px) {
  
  .text-block h1 {
    font-size: 2rem;
  }
  .text-block p{
    font-size: 1.25rem;
  }

  .text-block {
    width: 80%;
    text-align: center;
    padding-left: 0;
    top: 25vh;


  }

  .background-image-container{
    padding-top: 0;
    width: 100vw;
    height: 70vh;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  .personal_statement_container {
    flex-direction: column;
    padding: 0;
    margin-top: 50vh;
  }

  .personal_statement h3 {
    font-size: 1.25rem;
    letter-spacing: 3px;
  }

  .about_me_link{
    margin: 0 auto;
    margin-top: 3rem;

  }

  .about_me_text {
    padding: 1rem 3.5rem 1rem 3.5rem;
    font-size: 1.25rem;
  }

  .personal_pic_container {
    width: 100vw;
    height: 50vh;
  }
  .personal_pic_container img {
    width: 100vw;
  }
  
}
