/* Header.css */


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  background-color: #2b2b2b;
  overflow-x: hidden;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode:forwards;
}


@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

header {
  background:#2b2b2b;
  height: 5rem;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 5rem 1rem 5rem;
}

.ham_menu {
  background: transparent;
  border-color: transparent;
  font-size: 1.75rem;
  font-weight: 700;
  color: #C6A47E;
  cursor: pointer;
  padding-top: 1rem;
}

.close {
  padding-top: 4rem;
  padding-right: 2rem;
  background: transparent;
  border-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  color: #d5d5d5;
  cursor: pointer;
  align-self: flex-end;
}


.nav {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  transition: height 0.3s ease;
  padding-right: 1rem;
}


.nav ul {
  border-radius: 2rem;
  list-style: none;
  display: flex;
  gap: 2.5rem;
  font-size: 1rem;
  margin-right: 1rem;
  padding-left: 0.75rem;
  color: #ffffff;
  font-weight: 600;

}

.nav ul li a.active {
  color: #c6a47e; /* Color for active item */
}

.nav a {
  position: relative;  /* Add position relative to the anchor tag */
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav li:not(.logo_text):hover {
  width: 100%;
  color: #666666;
}


.logo_text {
  font-size: 1.5rem;
  padding-top: 0.75rem;
  font-weight: 700;
  color: #C6A47E;
  padding-left: 6rem;
  
}


.nav_side ul {
  position: fixed;
  margin-top: 0;
  padding-left: 0;
  top: 0;
  right: 0;
  height: 110dvh;
  width: 100%;
  overflow-y: 0;
  z-index: 5;
  background-color: #2b2b2b;
  display: none;
  flex-direction: column;
  align-items: center;
  list-style: none;
  gap: 1.5rem;
  font-size: 1.25rem;

}

.nav_side ul li {
  border-bottom: solid 1px #d5d5d5;
  width: 60%;
  padding-left: 20%;
  padding-bottom: 0.5rem;
  align-items: center;

}

.nav_side a {
  color: #C6A47E;
  text-decoration: none;
  position: relative;

}

.nav_side a::before  {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #C6A47E;
  transition: width 0.2s ease-in-out;
}

.nav_side li:hover a::before {
  width: 100%;
}

@media all and (max-width: 1024px) {
  .nav ul li, .login_btn {
    display: none;
  }
  .nav {
    gap: 0.5rem;
    padding-right: 0rem;
  }
 
  .logo_text {
    font-size: 1.25rem;
    padding-top: 0rem;
    padding-left: 0;

  }

  header {
    background:#2b2b2b;
    height: 5rem;
    top: 0;
    left: 0;
    width: 100vw;
    align-items: center;
    padding: 2rem 0rem 0rem 0.5rem;
    border-bottom: solid 1.5px #C6A47E;
  }

  .ham_menu {
    padding-top: 0;
  }


}

@media all and (min-width: 1024px) {
  .ham_menu {
    display: none;
  }

  .nav_side {
    display: none;
  }
}
