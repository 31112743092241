/* Footer.css */
footer {
    background-color: #1C1C1C;   
    color: #d5d5d5;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    padding-left: 2rem;
    padding-bottom: 0.5rem;

  }

.footer-contact-info ,.footer-location-info {
  margin-left: 0.5rem;
}

.footer_contact_li {
  display: flex;
  font-size: 1.1rem;
}
  
.footer_txt::before {
  content: "";
  display: block; 
  border-top: 1px solid #d5d5d5;
  margin-bottom: 10px;
  margin-right: 2rem;
 } 

.footer_info_container {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  font-size: 1.25rem;
  padding-top: 1rem;
 }

 .footer_info{
  list-style: none;
  padding: 0;
  margin: 0;
  width: 18rem;

 }

 .footer_link_projects {
   text-decoration:none ;
   color: #ffffff;
   border-bottom: solid 2px #C6A47E;
 }

 .footer_icon {
  margin-right: 1rem;
  color: #C6A47E;
 }


.footer_info li h2 {
  font-size: 1.25rem;
  color: #C6A47E;
}

.footer_info li p {
  font-size: 1.1rem;
}

.social_apps {
  padding-top: 1rem;
  color: #C6A47E;
  
}

.social_icon {
  cursor: pointer;
  margin-right: 1rem;
}

 @media all and (max-width:600px) {
  footer {
    padding-left: 1rem;
    padding-right: 0rem;
  
  }

  .footer_info_container {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    gap: 1rem;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 3rem;
  }


   .footer-contact-info ,.footer-location-info {
    margin-left: 0rem;
  }

  .footer-contact-info span{
    font-size: 1rem;
  }

  .footer_info li h2 {
    font-size: 1.3rem;
  }
.footer_info {
  width: 95%;

}
 
 }