
.contact_sec {
    background-color: #2b2b2b;
  }

.contact_container {
  display: flex;
  justify-content: space-between;
}

.left_contact_part {
  width: 50vw;
  background-color: #2b2b2b;
  padding-left: 10rem;

}

.contact_details {
  width: 50vw;
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #444444;
  padding-right: 6rem;
  padding-top: 2rem;
  margin-bottom: 0;
  margin-top: 0;
}

.contact_details iframe{
   width: 90%;
   height: 18rem;
   margin-bottom: 1rem;
}


.contact_icon {
  color:#C6A47E ;  
  font-size: 1.5rem;
  font-weight: 600;

}

.contact_li {
  display: flex;
}

.contact_info {
  margin-left: 1rem;
  color:#C6A47E ;  
  font-size: 1.25rem;

}

.h_contact {
  color: #C6A47E;
  font-size: 3.5rem;

}

.p_contact {
  color: #e3cfb9;
  font-size: 1.25rem;
}

.contact_form form {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  color: #C6A47E;
  line-height: 2;
  width: 28vw;
  
}

.send_button {
  font-size: 1rem;
  cursor: pointer;
  background-color: #C6A47E;
  margin-top: 1rem;
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  width: 5.5rem;
}

.send_button:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}

.contact_input {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.contact_form form textarea {
  height: 12vh;

}

@media all and (max-width:1024px) {
  .contact_details {
    margin-left: 0;

  }
  
  .contact_sec{
    display: flex;
    flex-direction: column;  
  }

  .contact_container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .left_contact_part {
    padding-left: 1rem;
    width: 100%;
  }

  .contact_form form {
    width: 90vw;
    margin-bottom: 2.5rem;
  }

  .contact_details {
    width: 100vw;
    padding-right: 0;

  }

  .contact_icon {
    font-size: 1.5rem;
  }

  .contact_info{
    font-size: 1.25rem;
  }

  .send_button {
    padding: 0.75rem 5rem 0.75rem 3rem;
    
}

}