.image-slider {
  position: relative;
  left: 25%;
  width: 650px;
  height: 420px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.4);
}

.image-slider .images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-slider .images img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: scale(1.1);
  transition: all 500ms ease-in-out;
}

.image-slider .images img.active {
  opacity: 1;
  transform: scale(1);
}

.image-slider .back-btn,
.image-slider .next-btn {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 25px;
  background: rgba(0, 0, 0, 0.2);
  transition: all 300ms ease-in-out;
}

.image-slider .next-btn {
  right: 0;
}

.image-slider .back-btn:hover,
.image-slider .next-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}

.image-slider .thumbnails {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.image-slider .thumbnails img {
  width: 60px;
  cursor: pointer;
  border: 2px solid transparent;
}

.image-slider .thumbnails img.active {
  border: 2px solid #fff;
}

.image-slider .back-btn:before,
.image-slider .next-btn:before {
  content: '\276E';
  display: block;
}

.image-slider .next-btn:before {
  content: '\276F';
}