/* About.css */
.about_sec {
    background-color: #2b2b2b;
    scroll-behavior: smooth;
  }

 
.about_text_container h1 {
    font-size: 3.5rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: left;
    color: #c6a47e;
    font-weight: 500;

  }


.p_about {
    width: 70%;
    font-size: 1.25rem;
    color: #d5d5d5;
    line-height: 1.3;
  }


.about_text_container {
  display: grid;
  place-items: center;
  padding-left: 4rem;
}

.cv_download_button {
  font-size: 1rem;
  cursor: pointer;
  background-color: #C6A47E;
  margin-top: 1rem;
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  width: max-content;
}

.cv_download_button:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}

.education_container {
  margin: 2rem 4rem 3rem 4rem ;
  padding: 3rem 4rem 0rem 11.5rem;
  background-color: #555555;
}

.education_h {
  color: #d5d5d5;
  font-size: 2.5rem;
  font-weight: 400;
}

.education_info {
  display: flex;
  justify-content: space-between;
}

.education_year {
  color: #C6A47E;
  font-size: 1rem;

}

.education_year h3 {

  font-size: 1.2rem;
  margin-top: 0;
  color: #957b5f;
}

.education_year h2 {
  font-size: 1.11rem;
  color: #d5d5d5;
  margin-top: 0;

}

.education_study {
  width: 50vw;
  margin-bottom: 3rem;
}

.education_study h2 {
  font-size: 1.1rem;
  color: #d5d5d5;
  font-weight: 700;
  margin-top: 0;

}

.education_study ul li {
  color: #d5d5d5;
}

@media all and (max-width:1024px) {
  .about_sec {
    padding-left: 0rem;
    text-align: center;

  }

  .about_text_container h1  {
    margin-top: 1rem;
    font-size: 2.5rem;
  }

  .about_text_container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .p_about {
    width: 89%;
    font-size: 1.1rem;
  }

  .education_container {
    width: 100%;
    margin: 2rem 0rem 0rem 0rem ;
    padding: 3rem 2rem 0rem 2rem;
    
  }

  .education_info {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .education_h {
    text-align: left;
  }

  .education_year h2 {
    font-size: 1.25rem;
    
   }
   
   .education_year h3 {
    font-size: 1.25rem;
    font-weight: 700;
    
   }

  .education_study {
    width: 90%;
    margin-bottom: 3rem;
    padding-right: 0;
    line-height: 2;

    }

  

  .education_study h2 {
    font-size: 1.25rem;
    font-weight: 700;

  }


}