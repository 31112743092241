/* Projects.css */
.projectone_sec {
    height: 100%;
    width: 100vw;
    background-color: #2b2b2b;

  }


.projectone_h {
  font-size: 1.5rem;
  color: #d5d5d5;
  font-weight: 400;
  padding-left: 16rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 2px;

}

.project_description_container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2rem;
    gap: 3rem;
    width: 65vw;
    padding-bottom: 2rem;
}

.project_description_title {
    width: 50%;
}

.project_description_title h2 {
    color: #C6A47E;
    letter-spacing: 2px;
}

.project_description_title h3 {
    color: #d5d5d5;
    letter-spacing: 2px;
}

.project_description_title p {
    color: #d5d5d5;
    letter-spacing: 2px;
}

.project_description_p {
    width: 50%;
    color: #d5d5d5;
    word-spacing: 4px;

}



  
  

@media all and (max-width: 1024px) {
  

  .projectone_h {
    padding-left: 1.5rem;
  }

  .project_description_container {
    display: block;
     width: 90vw;
  
  }

  .project_description_title {

     width: 100%;
     padding-bottom: 2rem;
  }
  
  .project_description_p {

     width: 100%;
  }

  
}