/* Gallery.css */

.gallery_sec {
  background-color: #2b2b2b;
  width: 100vw;
  padding-bottom: 2rem;
  border-bottom: solid 2px #957b5fc7;

}

.gallery_intro {
    display: grid;
    place-items: center;
    padding-bottom: 3rem;
}

.gallery_intro h1{
    color: #C6A47E;
    font-size: 3.5rem;
    font-weight: 500;
}

.gallery_intro p {
    width: 50vw;
    text-align: center;
    color: #d5d5d5;
    line-height: 1.6;

}
  @media all and (max-width: 1024px) {

    .gallery_intro p {
      width: 90%;
      font-size: 1.1rem;
    }

    .gallery_intro h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    


  }