/* Projects.css */
.projects_sec {
    width: 100vw;
    overflow: hidden;
    background-color: #2B2B2B;
    display: grid;
    place-items: center;
  }


  
.h_projects {
    font-size: 1.75rem;
    padding-left: 4rem;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    color: #D5D5D5;
    font-weight: 400;
  }

.projects_container {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  width: 100vw;

}

.projects_sample {
  text-decoration: none;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 20rem;
  gap: 3rem;
}


.project_image {
  width: 30rem;
  height: 22.5rem;
}

.project_sample_block {
  width: 35vw;

}

.project_sample_block h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #D5D5D5;
}

.project_sample_block h3 {
  cursor: pointer;
  font-size: 1.5rem;
  color: #957B5F;
  padding-bottom: 0.5rem;
}

.project_sample_block p {
  color: #D5D5D5;
  font-weight: 600;
  line-height: 1.75;
}

.view_project_text {
  font-size: 1.1rem;
  text-decoration: none;
  color: #957B5F;
  cursor: pointer;
  font-weight: 700;
}

.explore_text_container {
  display: grid;
  place-items: center;
  margin: 0 auto;
  background-color: #C6A47E;
  cursor: pointer;
}

.explore_text{
  text-decoration: none;
  color: #2b2b2b;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border: solid #C6A47E 0.15rem;
  
}

.explore_text:hover {
  background: #2b2b2b;
  border: solid #C6A47E 0.15rem;
  color: #C6A47E;
}


@media all and (max-width: 1024px) {
  .projects_container {
    flex-direction: column;
    margin-left: 0;
    margin-top: 3rem;
  }

    .projects_sample {
    display: flex;
    flex-direction: column;
    width: 100vw;
    border-left: 0;
    padding-bottom: 2rem;
    padding: 0;
    
  }
  .projects_sample img {
    width: 100vw;
    height: 20rem;

  }

  .project_sample_block {
    width: 100%;
    padding: 0 2.5rem 0 2.5rem;
    letter-spacing: 1px;
  }

  .h_projects {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 2rem;
  }


  .projects_p {
    width: 100%;
  }

  .explore_text_container {
    text-align: center;

  }

  .projects_sec {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
}